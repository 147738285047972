<template>
    <div v-if="$store.state.isModalOpenTwo" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">Add New Contact</h1>
                            <button
                                @click="onClickClose"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="pb-2">
                    <div class="row py-1 mx-auto">
                        
                        <slot></slot>

                        <div class="col-12 mb-1">
                            <label class="form-label" for="fullName"> <span class="required">*</span> Full Name</label>
                            <input
                                name="amount" @input="formError.full_name = ''"
                                type="text" v-model.trim="formData.full_name"
                                class="form-control"
                            />
                            <transition name="fade">
                                <span v-if="formError.full_name" class="invalid-feedback d-block qcont">{{ formError.full_name }}</span>
                            </transition>
                        </div>

                        <div class="col-12 mb-1">
                            <label class="form-label" for="mobileNo"> <span class="required">*</span> Mobile No (017xxxxxxxx)</label>
                            <input
                                name="mobile" @input="formError.mobile_no = ''"
                                type="text" v-model.trim="formData.mobile_no"
                                class="form-control"
                            />
                            <transition name="fade">
                                <span v-if="formError.mobile_no" class="invalid-feedback d-block qcont">{{ formError.mobile_no }}</span>
                            </transition>
                        </div>
                    </div>

                    <div class="col-12 text-center">
                        <SubmitCancelBtn
                            class="text-center"
                            @onSubmit="submit"
                            @onCancel="onClickClose"
                            :loading="loading"
                        />
                    </div>

                </div>
            </div>
        </div>
        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import Loader from "@/components/atom/LoaderComponent";
import handleRole from "@/services/modules/role";
import handleCompanyContact from "@/services/modules/companyContact";
import SubmitCancelBtn from '@/components/atom/SubmitCancelBtn.vue';
import { inject, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';

const props = defineProps({
    onCreateProfile: {
        type: Function,
        default: () => {}
    },
    role: {
        type: String
    },
    additionalProperties: {
        type: Object,
        default: {}
    }
});

const emit = defineEmits(['onCreateProfile']);

const $store = useStore();
const $route = useRoute();
const modalRef = ref(null);
const loading = ref(false);
const loader = ref(false);
const { fetchCompanyDefaultRoles } = handleRole();
const { storeCompanyContact, storeAdditionalContactItems } = handleCompanyContact();
const showError = inject('showError');
const showSuccess = inject('showSuccess');

const formData = reactive({
    full_name: null,
    mobile_no: null,
    birthday: null,
    age_year: null,
    age_month: null,
    age_day: null,
    gender: null,
    referred_by: null,
    company_rules: [],
    company_roles_data: [],
    contact_id: '',
    contact_type: 'person',
    active_tab: 'basic-info',
    profile_type: 'user',
})

const formError = reactive({
    full_name: '',
    mobile_no: ''
})

//Functions and methods
const submit = async() => {
    if(checkValidation()) return;
    const query = getQuery();
    loading.value = true;

    await storeCompanyContact(formData, query).then( async (res) => {
        loading.value = false
        if (!res.status) return showError(res.message)
        const {mobile_no, referred_by} = formData
        const resData = res.data;
        resData.mobile_no = mobile_no;
        await saveAdditionalContactItems(res.data.id, { mobile_no, referred_by }, query)

        emit('onCreateProfile', resData);
        onClickClose();
        showSuccess(res.message)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const saveAdditionalContactItems = async (contactId, keys, getQuery) => {
  await storeAdditionalContactItems(contactId, keys, getQuery).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const checkValidation = () => {
    let flag = false;

    if(!formData.full_name){
        formError.full_name = 'Full name is required'
        flag = true;
    }

    if(!formData.mobile_no){
        formError.mobile_no = 'Mobile no is required'
        flag = true;
    }

    return flag;
}

const onClickClose = () => {
    $store.state.isModalOpenTwo = false;
}

function getQuery() {
  return `?company_id=${$route.params.companyId}&roles=${props.role}`;
}

const getCompanyRoles = async () => {
    if(!props.role) return;

    const query = getQuery();

    await fetchCompanyDefaultRoles(query).then((res) => {
        if(res.data) {
          res.data.forEach(item => {
            formData.company_rules.push(item.id);
            formData.company_roles_data.push({
              is_new: false,
              id: item.id,
              label: item.name,
              serial_no: '',
              status: 'active',
            });
          });
        }
    })
}

onMounted( async () => {
    if(Object.keys(props.additionalProperties).length){
        for (const key in props.additionalProperties) {
            if(! props.additionalProperties[key]) continue;
            formData[key] = props.additionalProperties[key];
        }
    }
    await getCompanyRoles();
})
</script>
